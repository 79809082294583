import React from 'react';
import {CFG, axiosHeader} from '../CFG';
import axios from 'axios';
import {Row, Col} from 'react-bootstrap';
import CardInfo from './CardInfo';
import { sessionGet } from '../usuario/Auth';
import  GraficoVendasMes  from './Graficos/GraficoVendasMes';
import  GraficoVendasDiaSemana  from './Graficos/GraficoVendasDiaSemana';
import {todayYmd} from '../util/date';
import DatePicker from 'react-date-picker';
import UltimasTransacoes from './Sinteticos/UltimasTransacoes';
import UltimasRecargasPagamentos from './Sinteticos/UltimasRecargasPagamentos';
import PosicaoEstoque from './Sinteticos/PosicaoEstoque';
import MaisConsumidos from './Sinteticos/MaisConsumidos';
import MenosConsumidos from './Sinteticos/MenosConsumidos';

class Dashboard extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      empresa: sessionGet('empresa'),
      cal_inicio: new Date(),
      cal_fim: new Date(),
      inicio: todayYmd(),
      fim: todayYmd(),
      empresa_data: {},
      //series
      range_series: []
    };
    this.props.titlebar(this.props.title, this.props.description);
    this.loadRange();
    this.getEmpresaData();


    this.props.titlebar(this.props.title, this.props.description);
  }

  getEmpresaData = () => {
    axios.get(CFG.URL_API+'/empresa/'+this.state.empresa, axiosHeader()).then((response) => {
      this.setState({ empresa_data: response.data });
    });    
  }


  inicioChangeHandler = (date) => {

    if(date == null){
      this.setState({ cal_inicio: null, inicio: '' });
      return;
    }

    let REF = this;
    let dt = date;
    let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
    this.setState({ cal_inicio: dt, inicio: dt_format }); 
    setTimeout(() => REF.loadRange(), 500);
  }

  fimChangeHandler = (date) => {
    let REF = this;

    if(date == null){
      this.setState({ cal_fim: null, fim: '' });
      return;
    }

    let dt = date;
    let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
    this.setState({ cal_fim: dt, fim: dt_format }); 
    setTimeout(() => REF.loadRange(), 500);
  }

  loadRange = () => {
    let REF = this;
    this.get().then(function(response){
      let data_array = Object.entries(response.data).map(function(e){
        return e[1];
      });
      REF.setState({ range_series :  data_array });
    });
  }

  displayCards = () => {
    let REF = this;
    return this.state.range_series.map(function(opcao, tipo_opc){
          return (
            <Col xs="6" md="2" className="p-1" key={tipo_opc}>
              <CardInfo total={opcao.total} qtd={opcao.quantidade} icone={opcao.icone} titulo={opcao.titulo} empresa={REF.state.empresa}  />
            </Col>
          )
  });
  }

  get = () => {
    return axios.get(CFG.URL_API+'/relatorio/vendas-periodo?empresa='+this.state.empresa+'&inicio='+this.state.inicio+'&fim='+this.state.fim, axiosHeader())
  }
  
  render() {   
   
    return (
      <>
        
      </>
    );   
  }

}
  
  export default Dashboard;