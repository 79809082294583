import React from 'react'; // Import React
import {getListing, tableRender} from '../../CustomDataTable'; // Import React
import {Button, ButtonToolbar} from 'react-bootstrap';
import axios from 'axios';
import {CFG, axiosHeader} from '../../CFG';
import '../../DataTable.scss';
import Exame from './Exame';
import Filtro from './Filtro';
import { sessionGet } from '../../usuario/Auth';
import {buildQueryString} from '../../util/string';

class ExamesDataTable extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      refresh: false,
      empresa: sessionGet('empresa'),
      form:[],
      data: [],
      exame_show: false,
      filtro_show: false,
      searchTerm: null,
      activeIndex: undefined
    };
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.getListing = getListing.bind(this);
    this.tableRender = tableRender;
  }
  updateListing = (columnsSearch) => {
    let url_listing = buildQueryString('exame', columnsSearch);
    this.getListing(url_listing);
  }
  componentDidMount(){
    this.setState({ fetchComplete: true });
    this.setEmpresaLogo();
  }
  componentDidUpdate(prevProps, prevState){

    if(this.state.refresh !== this.props.refresh){
      this.setState({ refresh: this.props.refresh });
    }

    if(prevState.refresh !== this.state.refresh && this.state.refresh){
      this.updateListing();
    }
  }

  onKeyPressed(event){
    if(event.keyCode == 27 )
    this.setState({ popoverVisible : false });
  }

 
  setExameShow (status, data){
    this.setState({ form : data , exame_show: status });
  }

  setFiltroShow = (status) => {

    if(status)
    this.setFilters({});

    this.setState({ filtro_show: status });
  }

  setFilters = (filters) => {
    if(typeof filters.empresas_pair !== "undefined"){
      filters.empresas = filters.empresas_pair.map((e) => e.value);
    }
    this.setState({ filters });
    this.updateListing(filters);
  }

  getColumns(){
    return [
      {
        dataField: 'Id',
        text: 'ID',
        style: { 'text-align' : 'center' },
        headerStyle: { 'width' : '10%', 'text-align':'center' }
      },{
        dataField: 'consulta_format',
        text: 'Consulta',
      },{
        dataField: 'data_format',
        text: 'Data',
        sort: true
      },{
        dataField: 'hora',
        text: 'Hora',
      }
    ];
  }

  setEmpresaLogo = () => {
    let REF = this;
    axios.get(CFG.URL_API+"/empresa/"+sessionGet('empresa')+"/get-logo", axiosHeader())
    .then((response) => {
        REF.setState({ empresa_logo: "data:image/png;base64,"+response.data });
    });  
  }

  render(){
    let REF = this;
    return (
      <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
                <Button variant="success" onClick={() => REF.setFiltroShow(true)}>
                <span className="fa fa-filter"></span>
                </Button>
            </ButtonToolbar>            
          </div>
        </div>      
      <Filtro
        setfilters={this.setFilters}
        onHide={() => REF.setFiltroShow(false, {})}
        show={this.state.filtro_show}  
      />  
      <Exame
        onHide={() => REF.setExameShow(false, {})}
        tipo={this.state.tipo}
        show={this.state.exame_show}      
        form={this.state.form}
      />  
        {this.tableRender(false)}
      </div>
      </>
      )
    }
  }
  export default ExamesDataTable;
