import React from 'react';
import axios from 'axios';
import {recuperar} from '../util/email';
import {CFG, axiosHeader} from '../CFG';
import {Button, Alert} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { inputChangeHandler, isValid, nascimentoChangeHandler, inputBlurHandler, cepChangeHandler } from '../CustomForm';
import DatePicker from 'react-date-picker';
import './Login.scss';
import EmpresaSelect from '../empresa/EmpresaSelect';
import {usuario_lookup, empresa_lookup} from '../util/inputLookup';
import LoadingModal from  '../util/LoadingModal';

class LoginRecuperar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email_sent: false,
      autorizado: false,
      login_senha_ok: false,
      usuario_inexistente: false,
      razao: '',
      passoMax: 3,
      passoMin: 1,
      usuario: '',
      senha: '',
      empresa: '',
      validator: {},
      passo:1,
      info_passo: { 1: 'Insira login do usuário' }
    };

    
    this.props.titlebar(this.props.title, this.props.description);
    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.isValid = isValid.bind(this);
    
  }   

  emailSentSuccess = () => {
    return (
      <Alert key={1} variant={"success"}>
        Enviamos seu login e senha para {this.state.email_sent}  
    </Alert>
    );
  }

  usuarioNotFound(){
    return (
      <Alert key={1} variant={"warning"}>
    Este usuário não consta em nosso banco de dados
    </Alert>
    );
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.usuario != this.state.usuario)
    this.setState({ email_sent: false, usuario_inexistente: false });
  }

 

  submitSolicitar = () => {
    let Lr = this;

    this.setState({ loading: true });

    return axios.get(CFG.URL_API+'/usuario?login='+this.state.usuario, axiosHeader())
    .then(function(response){
        if(response.data.length > 0){
          Lr.triggerEmail(response.data[0].id);
        }
        else{
          Lr.setState({  email_sent: false, usuario_inexistente: true });
          Lr.setState({ loading: false });
        }
      //Perform action based on response

      
    })
    .catch(function(error){
      //Perform action based on error
    });
  }

  triggerEmail = (id) => {
    let Lr = this;
    usuario_lookup(id).then(function(response){
  
      if(response.data != null){
          Lr.setState({ usuario_inexistente: false });
          Lr.sendEmail(response.data.login);
       
      }

    })

  }

  sendEmail = (login) => {
    let Lr = this;

    return axios.post(CFG.URL_API+'/usuario/lembrete-senha', {login: login}, axiosHeader())
    .then(function(response){
        Lr.setState({ loading:false, email_sent: response.data.email, usuario_inexistente: false });
      //Perform action based on response
    })
    .catch(function(error){
      //Perform action based on error
    });
  }

  postPasso = () => {
      if(this.state.usuario != '')
      return this.submitSolicitar();
    }

  render() {
    let Lr = this;
    return (    
      <>
      <LoadingModal show={this.state.loading} />
     <div className="page login-page">
  <div className="container d-flex align-items-center">
    <div className="form-holder has-shadow">
      <div className="row">
  
        <div className="col-lg-6 bg-white">
          <div className="form d-flex align-items-center">
            <div className="content h-100 d-flex align-items-center">
           <div className="form-group">
            <h3>Esqueci minha senha</h3>
            <input id="login-username" onKeyPress={Lr.onKeyPressed} placeholder="Usuário" type="text" name="usuario" required className="input-material form-control pl-2" value={this.state.usuario} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
            {
            (() => {  
              if(Lr.state.usuario_inexistente)
              return Lr.usuarioNotFound()

              if(Lr.state.email_sent)
              return Lr.emailSentSuccess();
              })()
          } 
          <Button onClick={this.submitSolicitar} className="mt-2 float-right"><i className="icon-arrow-right"></i><br/><small>Próximo</small></Button>
          </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="info d-flex align-items-center">
            <div className="content">
              <div className="logo">
                <h1>Lanchar Tecnologia</h1>
              </div>
              <p>{this.state.info_passo[this.state.passo]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</>
    );
  }


  onKeyPressed = (event) => {

    let code = event.keyCode || event.which;

    if(code == 13 ){
      this.postPasso();
    }  
  }


   componentDidMount(){
    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }




}
export default LoginRecuperar;