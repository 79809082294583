import React from 'react';
import axios from 'axios';
import {sessionGet} from '../usuario/Auth';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import {Alert} from 'react-bootstrap';
import Select from 'react-select';

class EmpresaMultiSelect extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            list: [],
            validator: {},
            values: []
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
    }

    componentDidMount(){
        this.get();
    }

    componentDidUpdate(prevProps, prevState){

        if(prevState.list != this.state.list){
            let REF = this;
            let values = REF.state.list.filter(([value, text]) => { return typeof REF.state.values.find((e) => e.value === value) !== "undefined" });
    
            setTimeout(() => REF.setState({ values }), 500);
                      
        }
    
    }

    inputChangeHandler(pair, actionMeta){
        this.setState({ value: pair });
        if(typeof this.props.change === "function")
          this.props.change(pair, actionMeta);
    }

    render(){

        if(!this.state.loading && this.state.list.length == 1)
        return (<Alert variant="warning">
            Lista vazia
        </Alert>);

        let REF = this;

        return (
            <>
                <Select isMulti={true} noOptionsMessage={() => "Lamento. Não há opções disponíveis"} isDisabled={this.props.readonly} name="empresas" value={REF.state.value} isClearable={true} placeholder="pesquisar e selecionar" id="f-empresas" onChange={this.inputChangeHandler} options={ this.state.list.map(([value, text]) => { return { value, label: "( "+(value)+" )".padEnd(10,' ')+text } }) } />
            </>
        );
    }

    get = () => {
        let CS = this; 

        axios.get(CFG.URL_API+'/empresa?selectMode=pluck&pluckKey=id&pluckValue=razao', axiosHeader())
        .then(function(response){
            let entries = Object.entries(response.data);
           
            let htmlList = [];
          
            htmlList = entries.sort(function(a,b){
                return (a[1] > b[1]) ? 1  : -1;
            });
            
            CS.setState({ list: htmlList, loading: false });

      })
        .catch(function(error){
      requestException(error);
 });
    }

}

export default EmpresaMultiSelect;
