import React from 'react';
import '../../Modal.scss';
import { dateTimeChangeHandler, selectChangeHandler } from '../../CustomForm';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import LoadingModal from '../../util/LoadingModal';
import url_btn_close from '../../img/icons/close.png';
import EmpresasSelect from '../../empresa/EmpresaMultiSelect';

class Filtro extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      empresas: undefined
    };

    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.dateTimeChangeHandler = dateTimeChangeHandler.bind(this);

  }

  componentDidUpdate(prevProps, prevState){

    if(this.props.loading != this.state.loading){
      let REF = this;
      REF.setState({ loading: this.props.loading });
      this.props.onHide();
    }

  }

  render () {
    let REF = this;
    return (
      <>
      <Modal   
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            Filtro do Relatório Passagem de Turno
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body style={{ height: '60vh' }}>  
        <Row className="my-2">
            <Col md="12" className="p-1 ">
                <label>Empresa</label>
                <EmpresasSelect empresas={this.state.empresas} change={this.selectChangeHandler} />
            </Col>
          </Row>      
        <Row className="my-2">
            <Col md="6" className="p-1 ">
                <label className="d-block">de</label>
                <DateTimePicker locale="pt-BR" className="form-control" autoComplete="off" name="col_inicio" id="f-inicio" value={this.state.cal_inicio} onChange={(datetime) => REF.dateTimeChangeHandler(datetime, 'inicio')} />
            </Col>
              <Col md="6" className="p-1 ">
                <label className="d-block">até</label>
                <DateTimePicker locale="pt-BR" className="form-control" autoComplete="off" name="col_fim" id="f-fim" value={this.state.cal_fim} onChange={(datetime) => REF.dateTimeChangeHandler(datetime, 'fim')}  />
            </Col>
            </Row>
              
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }
  
  formSubmit = () =>  {

    this.setState({ loading: true });

    this.props.setfilters(this.state);

  }  
}

export default Filtro;