
import React from 'react';
import Img from 'react-image';
import load_gif from '../img/loading-2.gif';
import './Loading.scss';

function load(props){

    const cogStyle = {
        margin: 'auto',
        display: 'block'
    };

    return (
        <div hidden={props.show === false || (props.hidden)} className="loading-placeholder">
            
            <figure>
                <Img style={cogStyle} id="load" className={props.className} src={load_gif}  />
<figcaption hidden={typeof props.title === "undefined"}>{props.title}</figcaption>
            </figure>


        </div>
    )
}

export default load;