import React from 'react';
import axios from 'axios';
import { axiosHeader, CFG } from '../CFG';
import SimpleCrypto from "simple-crypto-js";

export function AuthCheck(){
    
    if(sessionGet('token') === null)
    return logout(true);

    updateSessao();
    this.setState({ session: true });
}


export function sessionRemove(keys){
    if(Array.isArray(keys)){
        for(let key of keys){
            localStorage.removeItem(CFG.SESSION_PREFIX+'.'+key);
        }
    } else {
        localStorage.removeItem(CFG.SESSION_PREFIX+'.'+keys);
    }
}

export function sessionGet(key){
    return localStorage.getItem(CFG.SESSION_PREFIX+'.'+key);
}

export function sessionSet(key,value){
    localStorage.setItem(CFG.SESSION_PREFIX+'.'+key, value);
}

export function updateEmpresa( conexao, empresa, razao){
    
    sessionSet('conexao', conexao);
    sessionSet('empresa', empresa);
    sessionSet('empresa_razao', razao);
}
export function updateUsuario(empresa,usuario, usuario_id){
    sessionSet('usuario', usuario);
    sessionSet('usuario_id', usuario_id);
}

export function startSessao( conexao, empresa,razao,usuario,usuario_id,location){

    updateEmpresa( conexao, empresa,razao);
    updateUsuario(empresa,usuario, usuario_id );
    let simpleCrypto = new SimpleCrypto(CFG.SESSION_PREFIX);
    
    setTimeout(() => {
            let token = { empresa, usuario, };
            sessionSet('token', simpleCrypto.encrypt(token));

            sessionSet('last_activity', Date.now());

            window.location.href=CFG.URL_APP+location;
    
    }, 1000);
     
}

export function updateSessao(){

    if(sessionGet('last_activity') == null)
    return logout(true);

let diff = Math.abs(new Date() - new Date(sessionGet('last_activity')));


//15 minutos
if(diff > (15 * 60000) || diff <= 0){
    removeAllSessionKeys();
    return logout(true);
} else {
    sessionSet('last_activity', Date.now());
}

}

export function removeAllSessionKeys(){
    sessionRemove(['token','permissions','email','usuario','senha','last_activity']);
}


export function logout(msg = false){
        document.body.innerHTML = "";
        removeAllSessionKeys();
   //     if(msg)
       // window.alert('Sessão expirada. Efetue o login novamente.');
        window.location.href=CFG.URL_APP+'/login';
}


export function logoutConfirm(){
    if(window.confirm("Tem certeza de que deseja sair?")){
        removeAllSessionKeys();
        window.location.href=CFG.URL_APP+'/login';  
      }
}

export default AuthCheck;