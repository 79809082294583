import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import EmpresaDropdown from './empresa/EmpresaDropdown';
import {sessionGet, logoutConfirm} from './usuario/Auth';
import './NavbarTop.scss';

class NavbarTop extends React.Component   {
	
	constructor(props){
		super(props);

		this.state = {
			show: this.props.show,
			show_sidebar: this.props.show_sidebar,
			buttonClass: 'fa-list',
			grupo: sessionGet('grupo')
		};
	}

	toggleSidebar = () => {
        let REF = this;

        if(this.state.show){
            this.setState({ buttonClass: 'fa-list', show: false });
          //  setTimeout(() => REF.setState({ extraClasses: '' }), 500);
        } else {
            this.setState({ buttonClass: 'fa-angle-left', show: true });
        }

        this.props.ontogglesidebar();
    }

		navbar(){
			const buttonClass = "fa "+this.state.buttonClass;

  return (
			<>
			<Navbar bg="light" expand="lg">
			<Button className="toggle d-block d-md-none" variant="primary" onClick={this.toggleSidebar}>
					<i className={buttonClass}></i>
				</Button>
			<Navbar.Brand>
			<EmpresaDropdown grupo={this.state.grupo} />

			</Navbar.Brand>
			<Button onClick={logoutConfirm} variant="secondary" className="ml-auto"> <span className="icon-exit" />  Sair</Button>

			{/*<Dropdown className="dropleft ml-auto">

						<Dropdown.Toggle variant="secondary" id="dropdown-basic" className="btn btn-default btn-icon">
							<span className="icon-cog" />
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Divider />
							<Dropdown.Item onClick={logoutConfirm}> <span className="icon-exit" />  Sair</Dropdown.Item>
						</Dropdown.Menu>
			</Dropdown>

			
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">

					</Nav>

				</Navbar.Collapse>
			*/}
			</Navbar>
			</>
  );
	}

	placeholder(){
		return (<div className="navbar-placeholder d-block"></div>)
	}

	render(){
		if(this.props.show)
		return this.navbar();
		else
		return this.placeholder();
	}
}

export default NavbarTop;