export function camelize(str) {
    return str.toString().replace(/\W+(.)/g, function(match, chr)
     {
          return chr.toUpperCase();
      });
  }

  export function def(chain){
      try{
          let lookup = eval(chain);
        return true;
      } catch (e){
          return false;
      }
  }

  export function buildQueryString(root, params = {}){
  
    let entries = Object.entries(params);
    let query_length = 0;
    let final_string = '';
    entries = entries.map(([k, e], i) => {

        if(typeof e === "undefined")
        return '';

        query_length++;

        if(query_length === 1)
        final_string += '?'+k+'='+encodeURI(e);
    
        final_string += '&'+k+'='+encodeURI(e);
    })
  
    return root+final_string;
  }
  


  export default def;