import React from 'react';
import axios from 'axios';
import {startSessao, AuthCheck, sessionSet} from './Auth';
import {CFG, axiosHeader} from '../CFG';
import {Button, Alert} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { isValid, selectChangeHandler } from '../CustomForm';
import './Login.scss';
import UsuarioEmpresaSelect from '../usuario/UsuarioEmpresaSelect';
import {inputValidate, cnpj_lookup} from '../util/inputValidate';
import inputMask from '../util/inputMask';
import {sessionGet} from '../usuario/Auth';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autorizado: false,
      login_senha_ok: false,
      cnpj_existente: false,
      cnpj_inexistente: false,
      usuario_inexistente: false,
      razao: sessionGet('empresa_razao'),
      passoMax: 3,
      passoMin: 1,
      cnpj: sessionGet('cnpj'),
      usuario: '',
      ativa: 'S',
      senha: '',
      empresa: undefined,
      validator: {},
      sem_conexao: false,
      passo: 1,
      empresa_bloqueada: false,
      info_passo: { 1: 'Insira CNPJ para acessar', 2: 'Insira Usuário/Login para acessar', 3: 'Escolha a unidade para acessar' }
    };

    if(typeof this.props.titlebar == "function")
    this.props.titlebar(this.props.title, this.props.description);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.isValid = isValid.bind(this);

    this.selectChangeHandler = selectChangeHandler.bind(this);

  }

  componentDidMount(){
    this.setState({ sem_conexao: false });
  }

  avancaPasso = () => {
    if(this.state.passo + 1 <= this.state.passoMax && this.isValid())
    this.setState({ passo: this.state.passo+1 });
  }

  voltaPasso = () => {
    if(this.state.passo - 1 >= this.state.passoMin && this.isValid())
    this.setState({ passo : this.state.passo-1 });
  }

  onKeyPressed = (event) => {
    let code = event.keyCode || event.which;

    if(code == 27 ){
      if(this.state.passo - 1 >= this.state.passoMin && this.isValid())
      this.setState({ passo : this.state.passo-1 });
    }

    if(code === 13) { //13 is the enter keycode
      this.postPasso(this.state.passo);
    }  
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.cnpj != this.state.cnpj)
    this.setState({ cnpj_inexistente: false });

    if(prevState.usuario != this.state.usuario || prevState.senha != this.state.senha){
      this.setState({ usuario_inexistente: false });
    }
  }
     
  buscaCnpj = () => {
    let REF = this;
    return axios.get(CFG.URL_API+'/login-empresa?cnpj='+encodeURIComponent(this.state.cnpj))
    .then(function(response){

      REF.setState({  empresa_bloqueada: false });

      if(typeof response.data.id !== "undefined"){

      sessionSet('Conexao', response.data.id);

        REF.setState({ 
          sem_conexao: false, 
          cnpj_inexistente: false,
          conexao: response.data.id,
          ativa: response.data.ativa,
          cnpj_existente: true,
          razao: response.data.razao });

        REF.avancaPasso();
      } else {
     
      REF.setState({
        sem_conexao: false, 
        cnpj_existente: false, 
        cnpj_inexistente: true });
      }

      //Perform action based on respinputChangeHandleronse
    })
    .catch(function(error){
      //REF.setState({ sem_conexao: true, cnpj_existente: false, cnpj_inexistente: false });

      //Perform action based on error
    });
  }

  cnpjNotFound = () => {
    return (
      <Alert key={1} variant={"warning"}>
    Este CNPJ não consta em nosso banco de dados
    </Alert>
    );
  }

  empresaAtiva = () => {
    return (
      <Alert key={1} variant={"warning"}>
    A empresa está bloqueada. Entre em contato com o suporte.
    </Alert>
    );
  }

  passoCnpj(){
    let REF = this;
    return (
    <div className="form-group">
    <input autoFocus={true} id="cnpj" placeholder="CNPJ" type="text" name="cnpj" required data-msg="Please enter your username" className="input-material form-control" value={this.state.cnpj} onChange={this.inputChangeHandler} />
    {
      (() => {  
        if(REF.state.cnpj_inexistente)
        return REF.cnpjNotFound()

        if(REF.state.ativa === 'N')
        return REF.empresaAtiva()

        if(REF.state.sem_conexao === true)
        return REF.semConexao()

       })()
    }
    <Button onClick={this.buscaCnpj} className="mt-2 float-right"><i className="icon-arrow-right"></i><br/><small>Próximo</small></Button>
    </div>);
  }

  semConexao = () => {
    return (
      <Alert key={1} variant={"warning"}>
    Não foi possível conectar ao banco
    </Alert>
    );
  }

  usuarioNotFound = () => {
    return (
      <Alert key={1} variant={"warning"}>
    Este usuário não pertence à empresa informada ou
    não consta em nosso banco de dados
    </Alert>
    );
  }

  inputChangeHandler(event) {
    this.setState({ [event.target.name] : inputMask(event.target) });
    let validate = inputValidate(event);
    this.state.validator[event.target.name] = validate;
  }

  passoLogin = () => {
    let REF = this;
    return (<div className="form-group">
      <h3>{REF.state.razao}</h3>
      <div className="text-right w-100">
      <Link to="/login-recuperar" className="text-muted"><small>Esqueci minha senha</small></Link>
      </div>
      <input id="login-username" placeholder="Usuário" type="text" name="usuario" required  className="input-material form-control" value={this.state.usuario} onChange={this.inputChangeHandler} />
      <input id="login-pass" placeholder="Senha" type="password" name="senha" required className="input-material form-control" value={this.state.senha} onChange={this.inputChangeHandler} />
      {
          (() => {

            if(REF.state.usuario_inexistente)
            return REF.usuarioNotFound()

            if(REF.state.sem_conexao === true)
            return REF.semConexao()

          })()
      } 
     <div>
      <Button className="btn btn-danger float-left mt-2 " onClick={this.voltaPasso}><i className="icon-arrow-left"></i> <br/><small>Voltar</small></Button>
    <Button onClick={this.submitLogin} className="mt-2 float-right"><i className="icon-arrow-right"></i><br/><small>Próximo</small></Button>
   </div>
   </div>);
  }

  passoEmpresa = () => {
    let REF = this;
    return (<div className="form-group">
    <UsuarioEmpresaSelect change={this.selectChangeHandler} usuario_id={this.state.usuario_id} />
    <Button className="btn btn-danger float-left mt-2 " onClick={this.voltaPasso}><i className="icon-arrow-left"></i> <br/><small>Voltar</small></Button>
    <Button onClick={() => REF.confirmaEmpresa()} className="mt-2 float-right" hidden={typeof this.state.empresa === 'undefined' || this.state.empresa === null}><i className="icon-arrow-right"></i><br/><small>Próximo</small></Button>
    </div>);
  }


  submitLogin = () => {
    let REF = this;

    return axios.get(CFG.URL_API+'/usuario?login='+this.state.usuario+'&senha='+this.state.senha, axiosHeader())
    .then(function(response){
      
      if(response.data.length > 0){
        REF.setState({ passo: 3, usuario_id: response.data[0].Id });
      }
      else {
        REF.setState({ usuario_inexistente: true, login_senha_ok: false  });
      }
      //Perform action based on response
    })
    .catch(function(error){
      REF.setState({ usuario_inexistente: true });
      //Perform action based on error
    });
  }

  confirmaEmpresa = () => {
    startSessao(this.state.conexao,
      this.state.empresa,
      this.state.razao,
      this.state.usuario,
      this.state.usuario_id, 
      "/");
  }

  postPasso = (passo) => {
    switch(passo){
      case 1:
        return this.buscaCnpj();
    break;
    case 2:
      if(this.state.validator.cnpj && this.state.cnpj != ''){
      
        return this.submitLogin();
      }
      else
      this.setState({ passo: this.state.passo -1 });
    break;
    case 3:
        if(this.state.usuario != '' && this.state.senha != ''
        && this.state.validator.usuario && this.state.validator.senha)
        return this.confirmaEmpresa();
        else
        this.setState({ passo: this.state.passo -1 });
    break;
    }
  }

  getPasso = (passo) => {
    switch(passo){
      case 1:
        return this.passoCnpj();
    break;
    case 2:
      return this.passoLogin();
   break;
    case 3: 
      return this.passoEmpresa();
    break;     
  
    }
  }
  
  render() {
    let REF = this;

    return (    
     <div className="page login-page">
  <div className="container d-flex align-items-center">
    <div className="form-holder has-shadow">
      <div className="row">
        {/* Logo & Information Panel*/}
       
        {/* Form Panel    */}
        <div className="col-lg-6 bg-white">
          <div className="form h-100 d-flex align-items-center">
           <div className="w-100 align-self-center align-center">
           <div className="w-100">
           <img src="/img/logo_transparent.png" className="d-block" />
           </div>
            
            <div className="content-flex d-block">
              {
                REF.getPasso(REF.state.passo)
              }
            </div>
            </div>
            </div>
          </div>
        <div className="col-lg-6">
          <div className="info d-flex align-items-center">
            <div className="content">
              <div className="logo">
                <h1>Easy<br/> <small>Gestão Clínica Veterinária</small></h1>
              </div>
              <p>{this.state.info_passo[this.state.passo]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
    );
  }

  componentDidMount(){
    document.addEventListener("keydown", this.onKeyPressed, false);

    let keyCheck = sessionGet('key');

    if(keyCheck !== null)
    window.location.href=CFG.URL_APP;

  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

}
export default LoginForm;