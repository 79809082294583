import React from 'react';
import { Link } from "react-router-dom";
import {Accordion, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import { sessionGet } from '../usuario/Auth';
import LoadingPlaceholder from '../util/LoadingPlaceholder';

class MenuVertical extends React.Component{

    constructor(props){
        super(props);

        this.menu = [];
        this.menu_ready = [];

        this.state = {
            menu: [],
            show: {},
            extraClasses: {},
            fetchComplete: false
        };

    }

    componentDidMount(){

        let REF = this;
        axios.get(CFG.URL_API+'/usuario-menu/'+sessionGet('usuario_id'), axiosHeader())
        .then((response) => {

            let menu_map = Object.entries(response.data);

            menu_map.sort((a,b) => {
                return a[1].menu_id < b[1].menu_id ? -1 : 1;
            });
            REF.menu = menu_map;   
            REF.setState({ fetchComplete: true });
            
        });
    }

 
    toggle = (menu_id) => {
        let REF = this;

        this.props.onchange();

        let show = {...REF.state.show};
        let extraClasses = {...REF.state.extraClasses};

        if(this.state.show[menu_id]){
             show[menu_id] = false;
             extraClasses[menu_id] = '';
            
        } else {
            show[menu_id] = true;
            extraClasses[menu_id] = 'active';
        } 

        this.setState({ show, extraClasses });
    }


    displayChildren = (m) => {
        let REF = this; 
        
        let children = REF.menu.filter(([id, sub], key) => {
            if(typeof REF.menu_ready[sub.menu_id] === "undefined"){
                return m.menu_id === sub.parent_id;
            }
        });
        
        return children.map(([id, sub], key) => {
                       
            return REF.renderMenus(sub);
              
            
        });
    }

    renderAccordion = (m) =>  {
        
        let REF = this;
        return (<Accordion>
            <Card>
                <Accordion.Toggle onClick={() => REF.toggle(m.menu_id)} className={"lv-1 " + this.state.extraClasses[m.menu_id]} as={Card.Header} eventKey={"t-"+m.menu_id}>
                    <div className="w-icon"><i className={"fa fa-" + (m.icone !== null && typeof m.icone !== "undefined" ? m.icone : "plus-square")}></i></div> <span className="text">{m.nome}</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={"t-"+m.menu_id}>
                    <Card.Body>
                        <ListGroup>
                            { REF.displayChildren(m) }
                        </ListGroup>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>); 
    }

    renderLink = (m) => {
      return (<ListGroupItem><Link to={m.tabela}><i className={"fa fa-" + (m.icone !== null && typeof m.icone !== "undefined" ? m.icone : "none")}></i> {m.nome}</Link></ListGroupItem>);
    }

    renderMenus = (m) => {
        let REF = this;
        let has_children = REF.menu.filter(([id, sub], key) => m.menu_id === sub.parent_id).length > 0;

        this.menu_ready[m.menu_id] = true;

        if(has_children){
            return REF.renderAccordion(m);
        }
     
        if(m.parent_id !== 0)
        return REF.renderLink(m);
             
    }

    display = () => {
        let REF = this;

        if(!REF.state.fetchComplete)
        return (<LoadingPlaceholder className="img-fluid" show={true} />);

        REF.menu_ready = [];
        return this.menu.map(([id, m], key) => {            
            if(typeof REF.menu_ready[m.menu_id] === "undefined"){
                return REF.renderMenus(m);
            }                 
        });           
    }

    render(props){
        let REF = this;
        return (
            <>
            <div className="w-logo-title">
                <Link to="/">
                    <div className="w-icon">
                        <img src="/img/logo_transparent.png" className="d-block img-fluid" />
                    </div>
                </Link>
            </div>           
            <section id="MenuVertical" className={this.props.className} >
                {
                    this.display()
                }            
            </section>
        </>);
    }

}   

export default MenuVertical;

