import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';

window.modalShow = false;

export function requestException(error){
    return console.log('Erro catalogado: Uma falha na conexão impossibilitou a obtenção/envio dos dados. Por gentileza, envie um print ao suporte.');
}

export function ErrorModal(props){

    let listErrors = (errors) => {
      
      if(typeof errors === "undefined")
      return (<></>);

      return errors.map((e, k) => (<li key={k}>{e}</li>));
      
    };

    return (<>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Confira os erros</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <ul>
          {listErrors(props.errors)}
        </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
        </>);
}

export default requestException;