import React from 'react';
import axios from 'axios';
import CFG from '../CFG';

export function e(){
    return null;
}

export function usuario_redefinir(login, empresa_id){
    return axios.get(CFG.URL_API+'/empresa?selectMode=pluck&pluckKey=id&pluckValue=nome&login='+login+'&empresa='+empresa_id)
     .then(function(response){
       return response.data;
       //Perform action based on response
     })
     .catch(function(error){
       //Perform action based on error
     });
}

export function recuperar(){
    
}

export default e;