import React from 'react';
import {CFG, axiosHeader} from '../../CFG';
import axios from 'axios';
import './Grafico.scss';
import Chart from 'react-apexcharts';
import {getWeek, daysUntil} from '../../util/date';
import {mask_decimal} from '../../util/inputMask';
import LoadingPlaceholder from '../../util/LoadingPlaceholder';

class GraficoVendasDiaSemana extends React.Component {
      
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      empresa: this.props.empresa,
      options: this.getOptions(),
      series: []
    };

  }

  componentDidMount(){
    this.loadRange();
  }

  getOptions = function() {

    let dt = new Date();
    let days = daysUntil(dt.getDay()+1).map((e) => e.substr(0,3));


   return {
      dataLabels: {
        enabled: false
      },

      stroke: {
        width: [1, 1, 4]
      },
      xaxis: {
        categories: days,
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            formatter: function (value) {
              return "R$ "+mask_decimal(value.toFixed(2));
            },
            style: {
              color: '#008FFB',
            }
          },
          title: {
            text: "Valor (em reais)",
            style: {
              color: '#008FFB',
            }
          },
          tooltip: {
            enabled: true
          }
        }  

      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    }
  };

  getSeries = () => [{
      tipo: 15,
      name: 'Débito',
      type: 'column',
      data: []
    }, {
      tipo: 16,
      name: 'Crédito',
      type: 'column',
      data: []
    }, {
      tipo: 5,
      name: 'Dinheiro',
      type: 'column',
      data: []
    }];

  loadRange = () => {

    var REF = this;

    let dt = new Date();
    let days = daysUntil(dt.getDay()+1).map((e) => e.substr(0,3));

    var series_up = this.getSeries();
    let req_count = 0;
    series_up.map(function(s){
      REF.get(s.tipo).then(function(response){

        let data_up = Object.entries(response.data).map(function(e){
          return e[1];
        });        

        let data_up_sync = days.map((e) => 0);

        data_up.map(function(d,i){
          data_up_sync[i] = mask_decimal(d.total);
        });

        s.data = data_up_sync;


        REF.setState({
          series:[...REF.state.series, s]
        });


        req_count++;

        if(req_count == 3)
        REF.setState({ loading: false });

      });



    });  
  }

  get = (tipo) => {
    let dt = new Date();
    return axios.get(CFG.URL_API+'/relatorio/vendas-semana?empresa='+this.props.empresa+'&tipo='+tipo+'&semana='+(getWeek()-1)+'&ano='+dt.getFullYear(), axiosHeader())
  }

  isLoading = () => {
   return this.state.loading ? "d-block" : "d-none";
  }

  render() {
    return (
      <div className="Grafico">
    <LoadingPlaceholder show={this.state.loading}/>
    <h4>Vendas da semana</h4>
    <div id="chart">
      <Chart hidden={this.state.loading} options={this.state.options} series={this.state.series} type="bar" height="350" />
    </div>
  </div>);
  }
}

export default GraficoVendasDiaSemana;