import React from 'react';
import Login from './usuario/Login';
import Dashboard from './dashboard/Dashboard';
import LoginRecuperar from './usuario/LoginRecuperar';
import {CacheSwitch, CacheRoute} from 'react-router-cache-route';
import {Route} from 'react-router-dom';

import Error404 from './error/Error404';

import ExamesDataTable from './consulta/exame/ExamesDataTable';

export function Routes(props){
    return (
        <>
        <CacheSwitch>
            <Route path="/login"  render={() => <Login title="Login" description="" titlebar={props.titlebar} />} />
            <Route path="/login-recuperar"  render={() => <LoginRecuperar title="Recuperar" description="" titlebar={props.titlebar} />} />
            <Route path="/passagemdeturno"  render={() => <ExamesDataTable title="Relatório - passagem de turno" description="" titlebar={props.titlebar} />} />
            <CacheRoute when="always" exact path="/" render={() => <Dashboard title="Dashboard" description="" titlebar={props.titlebar} />}/>
            <Route path='*' exact={true} render={() => <Error404 title="404 - Temporariamente indisponível" titlebar={props.titlebar} />}/>
        </CacheSwitch>
        </>
    );
}

export default Routes;