import React from 'react';
import {ENV, CFG} from './CFG';

import { BrowserRouter as Router } from 'react-router-dom'

import './normalize.css';
import './bootstrap.spacelab.min.css';
import './font-awesome.css';
import './App.scss';
import FooterCopyright from './FooterCopyright';

import { AuthCheck, logout, updateSessao} from './usuario/Auth';

import LayoutLeftSidebar from './layout/LayoutLeftSidebar';
import layoutMap from './layout/layoutMap';



class App extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      session: false
    };

    this.AuthCheck = AuthCheck.bind(this);

  
  }



  componentDidMount(){
   
    if(!CFG.IGNORE_AUTH.includes(window.location.pathname.replace(/\//g, ''))){

      if(ENV === 'production')
          this.AuthCheck();   
        

       } 

  }

  render() {
    const LayoutSetup = layoutMap() == undefined ? LayoutLeftSidebar : layoutMap();
   
      return (
        <Router>
          <LayoutSetup/>
        <div className="fixed-bottom-placeholder"></div>
        <div className="fixed-bottom">
          <FooterCopyright></FooterCopyright>
        </div>
        </Router>
        );
   
  }
}
  
  export default App;