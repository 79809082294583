import React from 'react';
import axios from 'axios';
import CFG from '../CFG';
import {cpf_lookup, cnpj_lookup} from './inputLookup';
export function validate_senha_decode(input){
  
  var re = /^([\d]{4})$/

	return re.test(input.value);
}


export function validate_qtd(input){
  return validate_integer(input);
}

export function validate_prvenda(input){
  return validate_decimal(input);
}

export function validate_cofins(input){
  return validate_decimal(input);
}
export function validate_custo(input){
  return validate_decimal(input);
}

export function validate_icms(input){
  return validate_decimal(input);
}
export function validate_icms_st(input){
  return validate_decimal(input);
}
export function validate_ipi(input){
  return validate_decimal(input);
}
export function validate_mva(input){
  return validate_decimal(input);
}
export function validate_pis(input){
  return validate_decimal(input);
}
export function validate_preco(input){
  return validate_decimal(input);
}
export function validate_tara(input){
  return validate_decimal(input);
}

export function validate_limite_d1(input){
  return validate_decimal(input);
}

export function validate_limite_d2(input){
  return validate_decimal(input);
}
export function validate_limite_d3(input){
  return validate_decimal(input);
}
export function validate_limite_d4(input){
  return validate_decimal(input);
}
export function validate_limite_d5(input){
  return validate_decimal(input);
}
export function validate_limite_d6(input){
  return validate_decimal(input);
}
export function validate_valor(input){
  return validate_decimal(input);
}
export function validate_valor_bruto(input){
  return validate_decimal(input);
}
export function validate_valor_liquido(input){
  return validate_decimal(input);
}

export function validate_decimal(input){

  if(typeof input == "undefined" || input == null)
  return 0;

  let valor = '';

  if(typeof input.value !== "undefined"){
    valor = input.value;
  } else {
    valor = input;
  }

  if(valor.length <= 3){
    return true;
  }

  var re = /(\d{1,3}\.)*([\d]{2,3}\,)*(\d{2})/;

return re.test(valor);
}

export function validate_integer(input){

    var re = /^[\d]*$/;

  return re.test(input.value);
}


export function validate_nascimento(input){

  let splitted = input.value.split('/');
  let [d,m,y] = splitted;

  if(d > 31 || m > 12 || y > new Date().getFullYear())
  return false;

  var re = /^\d{2}[\-\/]{1}\d{2}[\-\/]{1}\d{4}$/; // Pode usar ? no lugar do *
	if(re.test(input.value)){
		return input.value.replace(re,"$1/$2/$3");
	}
	return false;
}

export function validate_nparcela(input){
  return parseInt(input.value) <= 120; 
}

export function validate_celular(input){

  return true;
}

export function validate_telefone2(input){
  return validate_telefone(input);
}
  export function validate_telefone(input){

  var re = /^\([\d]{2}\)\s([\d]{4})\-?([\d]{4})$/

	return re.test(input.value);
}

export function validate_codbarra(input){

  var re = /^(\s|[0-9]|\-)+$/

	return re.test(input.value);
}

function validate_barras(input){
    return validate_codbarra(input);
}

export function validate_email(input){
  var re = /\S+@\S+\.\S+/;
  return re.test(input.value);
}

export function validate_cep(input){
  var re = /^([\d]{2})\.?([\d]{3})\-?([\d]{3})/;
  let v = input.value.toString().match(/\d+/g);
  if(v != null && v.join('').length == 8)
  return v.join('').replace(re,"$1.$2-$3");
  return false;
}

export function validate_cnpj(input){
  let re =  /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/;
  return re.test(input.value);
}

export function validate_cpf(input){
  
  if(input.value.length != 14){
    return false;
  }
 /* const cpfRegex = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/
  if(cpfRegex.test(input.value) == true)
  return true; */
  let cpf = input.value;
		cpf = input.value.split("");
		cpf.splice(3, 1);
		cpf.splice(6, 1);
		cpf.splice(9, 1);
		var digitoVerificador = 0;
		let soma = ((cpf[0] * 11) + (cpf[1] * 10) + (cpf[2] * 9) + (cpf[3] * 8) + (cpf[4] * 7) + (cpf[5] * 6) + (cpf[6] * 5) + (cpf[7] * 4) + (cpf[8] * 3) + (cpf[9] * 2) + (cpf[10] * 0));
		let resto = ((soma * 10) % 11);
		if (resto <= 9) {
			if (cpf[10] == resto) {

        return true;
       
 			} else {
				return false;
			}
		}
}



  export function validate_horario_de(input){
    return validate_horario(input);
  }
  export function validate_horario_ate(input){
    return validate_horario(input);
  }

  export function validate_horario(input){
    var re = /^(:|\d){1,4}$/;
    let valor = input.value;
  valor = valor.padStart(4, '0');
  valor = valor.substring((valor.length)-4);
    return re.test(valor);
  }


  export function validate_hsaida(input){
    return validate_horario(input);
  }

export function inputValidate(event){

  try {
  let validate = true;

  let validateInput = eval("validate_"+event.target.name);

  if(typeof validateInput !== "undefined")
  validate = eval("validate_"+event.target.name)(event.target);

  if(validate == null || event.target.value.length == 0 || event.target.type == 'radio'){
    event.target.classList.remove('is-invalid');
    event.target.classList.remove('is-valid');
    return true;
  }
  
  if(validate){
    event.target.classList.add('is-valid');
    event.target.classList.remove('is-invalid');
  } 
  if(!validate){
    event.target.classList.add('is-invalid');
    event.target.classList.remove('is-valid');
  }
  return validate;
} catch(e){
  if (e instanceof ReferenceError) {
    event.target.classList.remove('is-invalid');
    event.target.classList.remove('is-valid');
    return true;
  }
}

}
export default inputValidate;