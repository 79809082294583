import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from '../Routes.js';

class LayoutWide extends React.Component{
  render() {
    
    return (
            <Router>
                <Routes />
            </Router>
    );
  }
}
  
  export default LayoutWide;