import React from 'react';
import MenuVertical from '../menu/MenuVertical';
import { Col, Button } from 'react-bootstrap';
import './Sidebar.scss';

class Sidebar extends React.Component{

      constructor(props){
        super(props);

        this.state = {
            show: this.props.show          
        };
  }

  toggle = () => {
        let REF = this;

        if(this.state.show){
            this.setState({ buttonClass: 'fa-list', show: false });
          //  setTimeout(() => REF.setState({ extraClasses: '' }), 500);
        } else {
            this.setState({ buttonClass: 'fa-angle-left', show: true });
        }

        this.props.ontoggle();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.show !== this.props.show)
        this.setState({ show: this.props.show });
    }

    render(props){

        const backdropClass = "sidebar-backdrop "+(this.state.show ? "d-block" : "");
        return (
            <>
            <Col onMouseOver={this.props.onchange} id="Sidebar"  className={this.props.className} >
                 <MenuVertical ontoggle={this.props.ontoggle} show={this.state.show} onchange={this.props.onchange}  />
            </Col>
                    <div onClick={this.toggle}  className={backdropClass}></div>
            </>
        );
    }

}   

export default Sidebar;

