import React, { useRef } from 'react'; // Import React
import './DataTable.scss';
import axios from 'axios';
import {requestException} from './util/exception';
import {CFG, axiosHeader} from './CFG';
import {Row, Col} from 'react-bootstrap';
// es6
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import { Dropdown, Alert} from 'react-bootstrap';

import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ReactToPrint from 'react-to-print';
import {todayDmy} from './util/date';
import LoadingPlaceholder from './util/LoadingPlaceholder';
import { sessionGet } from './usuario/Auth';


export default function e(){
  return true;
}

  


  export function getListing(uri, filters = {}){

      this.setState({ fetchComplete: false, data: [], activeIndex: undefined });
    
        let REF = this;

        let qs_filters = '';
        var queryString = CFG.URL_API+'/'+uri;

        if(filters != {}){
          let f_list = Object.entries(filters);
          for (var [key, term] of f_list) {

            if(term === "")
            continue;
        
            qs_filters += '&'+key+'={'+term+'}';
          }
  
          if(qs_filters != ''){
            queryString += queryString.indexOf('?') == -1 ? '?' : '&';
            if(queryString.indexOf('whereMode') != -1)
            queryString += qs_filters;
            else
            queryString += 'whereMode=or'+qs_filters;
          }
        }


       return axios.get(queryString, axiosHeader())
        .then(function(response){
          if(response.data !== "" && response.data !== null){
            REF.setState({ fetchComplete: true, data : response.data, loading: false });
          } else {
            REF.setState({ fetchComplete: true, data : [], loading: false });
          }
          //Perform action based on response
        })
        .catch(function(error){
          requestException(error);
        });
      }

      export function printTable(props, rowClasses, selectRow, rowEvents, paginateOrNot){
        let REF = this;

        let expanded = this.state.data.filter((d) => d.expand_text !== null).map((d) => d.Id);
  

        const expandRow = {
          expanded,
          parentClassName: 'parent-expand',
          className: 'expand',
          expandByColumnOnly: false,
        renderer: row => (<div className="expand">{row.expand_text}</div>)         
      
        };
      
          return (
<>
          <BootstrapTable
          bootstrap4
            hidden={!this.state.fetchComplete}
            rowClasses={ rowClasses }
            { ...props.baseProps }
            expandRow={ expandRow }
            selectRow={ selectRow }
            keyField='Id' data={ this.state.data } columns={ this.getColumns() } pagination={ paginateOrNot } rowEvents={ rowEvents } 
            />
      <LoadingPlaceholder hidden={REF.state.fetchComplete}/>
      <Alert variant="info" hidden={!REF.state.fetchComplete || REF.state.data.length > 0}>Sem resultados para exibir...</Alert>
      </>
      );
            
      }

  

   export function printHeader(title) {
    let REF = this;
    
        return (
        <div className="print-only print-header">
          <table className="table">
            <tbody>
            <tr>
              <td><img src={REF.state.empresa_logo} style={{ width: "100px" }}/></td>
              <td>{title}</td>
              <td>Data: {todayDmy()}</td>
            </tr>
            </tbody>
          </table>
          </div>
          );
    }

  

      export function tableRender(sizePerPage, rowClasses){
        let REF = this;
        
        const componentRef = React.createRef();

        this.printTable = printTable.bind(this);
        
        if(typeof rowClasses === "undefined"){
          rowClasses = (row, rowIndex) => {
            let classes = '';
              classes += typeof row.dt_removido !== "undefined" && row.dt_removido != null ? 'removed' : '';
            
             return classes;
          };
        }       
    
        const selectRow = {
          mode: 'radio',
          hideSelectColumn: true,
          classes: 'active',
          clickToSelect: true
        };
        
        const rowEvents = {
          onClick: (e, row, rowIndex) => {
            this.setState({ activeIndex : rowIndex });
            if(typeof REF.setModalShow === "function")
            REF.setModalShow(true, row);
          }
        };
          
           const MainSearch = (props) => {
            let input;
            const handleFilterChange = () => {
              let results = props.onSearch(input.value);
    
              this.setState({ searchTerm : input.value  });
    
              let columnsSearch = {};
              for (var entry of this.getColumns()) {
                columnsSearch[entry.dataField] = input.value;
              }
    
              if(typeof REF.updateListing === "function")
              REF.updateListing(columnsSearch);
    
            };
       
            return (
              <input
                placeholder="Filtro: comece a digitar para filtrar"
                onChange={handleFilterChange}
                className="form-control"
                autoFocus
                style={ { backgroundColor: '#fff' } }
                ref={ n => input = n }
                type="text"
                defaultValue={this.state.searchTerm}
                />
              );
            };

            let paginateOrNot;

            if(typeof sizePerPage === "undefined")
            paginateOrNot = paginationFactory({ paginationSize: 5 });
          
            if(Number.isInteger(sizePerPage))
            paginateOrNot = paginationFactory({ paginationSize: sizePerPage });
          
            if(false === sizePerPage)
            paginateOrNot = false;

            REF.printHeader = printHeader.bind(REF);
                     
     
            return (
              <div>
       
              <ToolkitProvider
        
              keyField="Id"
              data={ this.state.data }
              columns={ this.getColumns() }
              search
              columnToggle
              >
              {
                props => (
                  <div>
                    <Row className="cdt-toolbar">
                        <Col className="col-md-12 justify-content-end d-flex">
                          <ReactToPrint
                            trigger={() => <button className="btn-sm btn-secondary btn-print mr-3 mb-2"><i className="fa fa-file-text-o"></i></button>}
                            content={() => componentRef.current}
                          />
                    
                        </Col>
                       
                    </Row>
                  <div ref={componentRef}>
                    {REF.printHeader(REF.props.title)}

                    {this.printTable(props, rowClasses, selectRow, rowEvents, paginateOrNot)}
                </div>
                  </div>
                  )
                }
                </ToolkitProvider>
                </div>);
      }