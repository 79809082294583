import React from 'react';
import {CFG} from '../CFG';

class Error404 extends React.Component{

  constructor(props){
    super(props);

    this.state = {

    };

    this.props.titlebar(this.props.title)
  }

  render (props){

    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 text-center"> 
            <a href={CFG.URL_APP} className="btn btn-lg inline-block btn-primary">Voltar à página índice</a>         
          </div>
        </div>      
      </div>);
    
}
}

  
export default Error404;