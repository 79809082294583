import React from 'react';


const semana = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado"
];

const meses = [
"Janeiro",
"Fevereiro",
"Março",
"Abril",
"Maio",
"Junho",
"Julho",
"Agosto",
"Setembro",
"Outubro",
"Novembro",
"Dezembro"
];

export function getMonthName(idx){

    var n = meses[idx];

    return n;
}

export function getWeek(){
    let dt = new Date();
    var onejan = new Date(dt.getFullYear(),0,1);
    return Math.ceil((((dt - onejan) / 86400000) + onejan.getDay()+1)/7);
}

export function daysUntil(n){
    return semana.slice(0,n);
}

export function monthsUntil(n){
    return meses.slice(0,n);
}

export function todayTimestamp(){
    let dt = new Date();
    return dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate()+' '+dt.getHours().toString().padStart(2, '0')+':'+dt.getMinutes().toString().padStart(2, '0')+':'+dt.getSeconds().toString().padStart(2, '0');
}

export function todayYmd(){
    let dt = new Date();
    return dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate();
}

export function todayDmy(){
    let dt = new Date();
    return dt.getDate().toString().padStart(2,'0')+'/'+(dt.getMonth()+1).toString().padStart(2,'0')+'/'+dt.getFullYear();
}

export function getFullTimestamp(dt){
    return dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate()+' '+dt.getHours().toString().padStart(2, '0')+':'+dt.getMinutes().toString().padStart(2, '0')+':'+dt.getSeconds().toString().padStart(2, '0');
}

export function getDmy(dt){
    
    return dt.getDate().toString().padStart(2,'0')+'/'+(dt.getMonth()+1).toString().padStart(2,'0')+'/'+dt.getFullYear();
}

export function getYmd(dt){
    return dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate();
}

export function parseUS(us_timestamp){
    let dt = {
        year: us_timestamp.substr(0,4),
        month: us_timestamp.substr(5,2),
        day: us_timestamp.substr(8,2)
    };



        Object.assign(dt, {
            hours: us_timestamp.substr(11,2),
            minutes: us_timestamp.substr(14,2),
            seconds: us_timestamp.substr(17,2)
        });
    

    return dt;
}

export function parseBR(br_timestamp){
    let dt = {
        day: br_timestamp.substr(0,1),
        month: br_timestamp.substr(3,2),
        year: br_timestamp.substr(6,4)
    };

        Object.assign(dt, {
            hours: br_timestamp.substr(11,2),
            minutes: br_timestamp.substr(14,2),
            seconds: br_timestamp.substr(17,2)
        });
    
    return dt;
}

export function objectToDate(dt){
    if(dt == null)
    return null;

    let result = new Date(dt.year, dt.month-1, (parseInt(dt.day)), dt.hours, dt.minutes, dt.seconds, 0);
    return result;
}

export function firstDateOfMonth(){
    let dt = new Date();
    return dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-01';
}

export function lastDateOfMonth(){
    let dt = new Date();
    let ld = new Date(dt.getFullYear(), dt.getMonth() +1, 0).getDate();
    return dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+ld.toString().padStart(2, '0');
}

export default todayYmd;