
import React from 'react';
import {sessionGet} from './usuario/Auth';

export const ENV = window.location.href.includes("easy.inf.br") ? "production" : "local";

export const CFG = (() => {

  if(ENV == 'production')
  return {
    APP_NAME: "Easy - Gestão Clínica Veterinária",
    SESSION_PREFIX: 'easy.intranet',
    IGNORE_AUTH : ['login', 'login-recuperar'],
    URL_APP : 'https://app.easy.inf.br',
    URL_API : 'https://data.easy.inf.br'
  };

  if(ENV == 'local')
  return {
    APP_NAME: "Easy - Gestão Clínica Veterinária",
    SESSION_PREFIX: 'easy.intranet',
    IGNORE_AUTH : ['login', 'login-recuperar'],
    URL_APP : 'http://127.0.0.1:3000',
    URL_API : 'http://192.168.0.1:8000'
  };

})();

export function axiosHeader(){
  return  {
    headers: {
      'Content-Type': 'application/json',
      'Set-Cookie': '{"conexao":'+sessionGet('Conexao')+'}',
      'Authorization': 'Bearer '+sessionGet('token') 
    }
  }
}

export default CFG;